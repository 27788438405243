

svg.separator-first {
    overflow: hidden;
    vertical-align: middle;
    fill:#f6f6f6;
    transform: rotate(180deg);
}
svg.separator-second {
    overflow: hidden;
    vertical-align: middle;
    fill:#f6f6f6;
    transform: rotate(0deg);
    -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.bg-almost-white {
    background: #f6f6f6;
}
.pledged_table {
    text-align: left !important;
    margin: 0px !important;
    padding: 20px !important;
}

.isShow {
    display:none;
}

.card h4 {
    font-size: 18px;
}
.race-header {
    text-align: center;
}

.event-card .main_info .race_element,
.user-card .main_info .user_element {
    align-items: center;
    line-height: 18px;
    margin-bottom: 10px;
    font-size: 15px;
    display: flex;
    border-top: 1px solid #ebeaf4;
    padding: 5px;
    overflow: hidden;
}
.event-card .main_info .race_element .label_value label,
.user-card .main_info .user_element .label_value label {
    text-transform: uppercase;
    color: #968e81;
    font-weight: 500;
    font-size: 11px;
    padding: 0;
    margin: 0;
}

.event-card .main_info .race_element .icon {
    -webkit-box-flex: 0 0 30px;
    -moz-box-flex: 0 0 30px;
    -webkit-flex: 0 0 30px;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    height: 30px;
    margin-right: 10px;
}
.event-card .main_info .race_element .label_value,
.user-card .main_info .user_element .label_value {
    -webkit-box-flex: 0 1 auto;
    -moz-box-flex: 0 1 auto;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    display: table;
}

.event-card .main_info .race_element .label_value .value,
.user-card .main_info .user_element .label_value .value {
    display: table-row;
    color: #3b515c;
    font-weight: 500;
}

.event_logo img {
    width: 100%;
}
