.payment-form { background: #F8F9FA;margin-top: 100px; padding: 40px; box-shadow:0px 0px 30px -12px #777;}
.btn{ min-width: 250px;}

.event-info-block h6 {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
}
.event-info-block ul {
    padding: 0;
    margin: 0;
}
.event-info-block ul li {
    list-style: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 5px 0;
    font-size: 14px;
    
}
.event-info-block ul li strong {
    display: block;
}
.event-info-block ul li:last-child{
    border-bottom: 0;
}
.event-info-block label {
    font-size: 14px;
    font-weight: 600;
}
.event-info-block .form-control {
    background-color: #ebeef0;
}
.event-info-block .donation-amount {
    background-color: #fff !important;
}